import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';

export const StyledGrid = styled(Grid)(
  ({ theme }) => ({
    'marginBottom': theme.spacing(2)
  }),
);

export const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
  }),
);
