/* eslint-disable */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CropFreeSharpIcon from '@mui/icons-material/CropFreeSharp';
// import Swiper core and required modules
import { Navigation, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useDispatch, useSelector } from 'react-redux';
import image from '../../assets/images/temp-image.png';
import {
  addRemoteUser,
  editRemoteUser,
  removeRemoteUser,
  addRemoteScreenUser,
  setScreenShareId,
  removeScreenUser,
  setAudioTrack,
  setRtc,
  setVideoTrack,
} from '../../ReduxSlices/AppSlice';
import MediaPlayer from './MediaPlayer';
import {
  appId,
  client,
  defaultScreen,
  screenClient,
} from '../../utilities/utils';


export default function Workspace() {
  const dispatch = useDispatch();

  const videoTrackState = useSelector(
    (state) => state.appState.rtc.users.main.videoTrack,
  );
  const audioTrackState = useSelector(
    (state) => state.appState.rtc.users.main.audioTrack,
  );
  const workChannelId = useSelector(
    (state) => state.appState.rtc.workChannelId,
  );
  const workChannelToken = useSelector(
    (state) => state.appState.rtc.workChannelToken,
  );
  const currentScreenShareUserId = useSelector((state) => state.appState.rtc.currentScreenShareUserId?.[0]);
  const screenTrackState = useSelector(
    (state) =>
      state.appState.rtc.screenUsers?.[currentScreenShareUserId]
        ?.videoTrack,
  );
  console.log(currentScreenShareUserId);
  const userId = useSelector((state) => state.appState.user.id);
  const userScreenId = useSelector(
    (state) => state.appState.user.screenId,
  );
  const allUsers = useSelector((state) => state.appState.rtc.users);

  // const [networkQuality, setNetworkQuality] = useState('');

  const getData = async () => {
    let microphones = [];
    let cameras = [];

    try {
      microphones = await AgoraRTC.getMicrophones();
    } catch (e) {
      // console.log(e);
    }

    try {
      cameras = await AgoraRTC.getCameras();
    } catch (e) {
      // console.log(e);
    }

    let microphoneTrack = null;
    let cameraTrack = null;
    // const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
    if (microphones.length > 0) {
      microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
    }
    if (cameras.length > 0) {
      cameraTrack = await AgoraRTC.createCameraVideoTrack();
    }
    dispatch(
      setRtc({
        microphones,
        cameras,
        microphoneTrack,
        cameraTrack,
      }),
    );
  };

  const handleUserJoined = (user) => {
    if (user.uid.length === 9)
      // if user is joined with the camera feed
      dispatch(addRemoteUser(user));
    else if (user.uid.length === 4) {
      // if user is joined with the screen feed
      if (user.uid !== userScreenId)
        dispatch(addRemoteScreenUser(user));
      dispatch(setScreenShareId(user.uid));
    }
  };

  const subscribe = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    dispatch(editRemoteUser(user));
  };

  const handleUserPublished = (user, mediaType) => {
    console.log('handlePublish', user.uid);
    if (user.uid !== userScreenId) subscribe(user, mediaType);
    // if (user.uid.length === 4)
    //   dispatch(editRemoteUser(user));
  };

  const handleUserUnpublished = (user) => {
    console.log('handleUnPublish', user.uid);
    if (user.uid.length === 9)
      dispatch(editRemoteUser(user));
    else if (user.uid.length === 4)
      dispatch(removeScreenUser(user.uid));
  };

  const handleUserLeft = (user) => {
    if (user.uid.length === 9)
      dispatch(removeRemoteUser(user.uid));
    // else if (user.uid.length === 4)
    //   dispatch(removeScreenUser(user.uid));
  };

  // const handleNetworkQuality = (stats) => {
  //   const { uplinkNetworkQuality, downlinkNetworkQuality } = stats;
  //   const signal = Math.max(
  //     uplinkNetworkQuality,
  //     downlinkNetworkQuality,
  //   );
  //   let color = 'white';
  //   if (signal < 1) {
  //     color = 'white';
  //   } else if (signal >= 1 && signal <= 2) {
  //     color = '#74c686';
  //   } else if (signal >= 3 && signal <= 4) {
  //     color = '#ffd75e';
  //   } else if (signal >= 5 && signal <= 6) {
  //     color = '#e97b86';
  //   }
  //   setNetworkQuality(color);
  // };

  const publish = async (videoTrack, audioTrack) => {
    // const userScreenID = `${data.userName}${defaultScreen.screenID}`;
    // await screenClient.join(
    //   appId,
    //   data.channelName,
    //   data.screenToken,
    //   userScreenID,
    // );

    const res = [];
    if (videoTrack?.enabled) {
      res.push(videoTrack);
    }
    if (audioTrack?.enabled) {
      res.push(audioTrack);
    }
    if (res.length > 0) {
      await client.publish(res);
    } else {
      // console.log('not publish!!');
    }
  };

  useEffect(() => {
    client.on('user-joined', handleUserJoined);
    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-left', handleUserLeft);

    async function fetchData() {
      await client.join(
        appId,
        workChannelId,
        workChannelToken,
        userId,
      );

      await getData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (
      workChannelId &&
      workChannelToken &&
      (videoTrackState || audioTrackState) &&
      userId
    ) {
      publish(videoTrackState, audioTrackState);
    }
  }, [videoTrackState, audioTrackState]);

  return (
    <MainWrapper>
      <CarouselWrapper>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={5}
          slidesPerView={5}
          navigation
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
        >
          {allUsers &&
            Object.keys(allUsers)
              .filter(
                (userIdItem) => currentScreenShareUserId ? true : userIdItem.toString() !== 'main',
              )
              .map((item) => {
                const user = allUsers[item];
                return (
                  <SwiperSlide key={`${item}-person`}>
                    <CarouselImageWrapper>
                      <MediaPlayer
                        videoTrack={user.videoTrack}
                        audioTrack={item === 'main' ? null : user.audioTrack}
                      />
                      <BottomBar>
                        <Typography color="primary">
                          {item.name}
                        </Typography>
                        <IconButton
                          size="small"
                          color="primary"
                          aria-label="enlarge screen"
                          edge="start"
                          // onClick={handleMenu}
                        >
                          <CropFreeSharpIcon fontSize="small" />
                        </IconButton>
                      </BottomBar>
                    </CarouselImageWrapper>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </CarouselWrapper>
      <MainScreen>
        <ImageWrapper>
          <MediaPlayer
            videoTrack={
              currentScreenShareUserId
                ? screenTrackState
                : videoTrackState
            }
            isLocal
            isVideoEnabled={
              currentScreenShareUserId
                ? screenTrackState?.enabled
                : videoTrackState?.enabled
            }
            audioTrack={null}
          />
          <BottomBar>
            <Typography color="primary">Gaurav</Typography>
            <IconButton
              size="small"
              color="primary"
              aria-label="enlarge screen"
              edge="start"
              // onClick={handleMenu}
            >
              <CropFreeSharpIcon fontSize="small" />
            </IconButton>
          </BottomBar>
        </ImageWrapper>
      </MainScreen>
    </MainWrapper>
  );
}

const MainWrapper = styled(Box)(() => ({
  width: '100%',
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  'margin': theme.spacing(3),
  '& .swiper-button-next, & .swiper-button-prev': {
    color: theme.palette.layout.black,
  },
}));

const CarouselImageWrapper = styled(Box)(() => ({
  'width': 'inherit',
  'borderRadius': '1rem',
  '& >div': {
    height: '150px',
    width: 'inherit',
    borderRadius: 'inherit',
  },
}));

const BottomBar = styled(Box)(({ theme }) => ({
  'display': 'flex',
  'justifyContent': 'space-between',
  'alignItems': 'center',
  'width': '100%',
  'position': 'absolute',
  'bottom': 0,
  'backgroundColor': theme.palette.layout.black,
  'opacity': '0.5',
  '& p': {
    marginLeft: theme.spacing(0),
  },
  '& buttton': {
    marginRight: theme.spacing(0),
  },
}));

const MainScreen = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
}));

const ImageWrapper = styled(Box)(() => ({
  'position': 'relative',
  'height': '55vh',
  'width': '60vw',
  'borderRadius': '1rem',
  '& >div': {
    height: 'inherit',
    width: 'inherit',
    borderRadius: 'inherit',
  },
}));
