/* eslint-disable */
import AgoraRTC from 'agora-rtc-sdk-ng';

export const drawerWidth = 99;

// export const client = AgoraRTC.createClient({
//   mode: 'rtc',
//   codec: 'vp8',
// });

const clientSingleton = (function () {
  let instance;

  function createInstance() {
    const object = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    });
    return object;
  }

  return {
    getInstance() {
      if (!instance) {
        console.log('creating instance!!');
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export const client = clientSingleton?.getInstance();

export const appId = 'a232dd59a8934e5fa193c172e3cc9a1b';

console.log('screenClient');
export const screenClient = AgoraRTC.createClient({
  mode: 'rtc',
  codec: 'vp8',
});

export const defaultScreen = {
  screenID: '_screen',
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const getToken = () => localStorage?.getItem('token');

export const isUserAuthenticated = () => {
  if (localStorage?.getItem('token')?.length > 0) return true;
  return false;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
