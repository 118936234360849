/* eslint-disable */
import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import SignUp from './modules/SignUp/SignUp';
import Login from './modules/Login/Login';
import Home from './modules/Home/Home';
import MainLayout from './modules/Common/MainLayout/MainLayout';
import Workspace from './modules/Workspace/Workspace';
import PrivateRoute from './modules/Common/PrivateRoute/PrivateRoute';
import {
  LOGIN,
  SIGNUP,
  HOME,
  WORKSPACE,
} from './constants/urlConstant';
import { getToken, isUserAuthenticated } from './utilities/utils';
import { resetSession } from './APIs/Services';
import { sessionReset } from './ReduxSlices/AppSlice';

function App() {
  // Local variables
  const dispatch = useDispatch();

  // Component Logic
  const resetMutation = useMutation(resetSession, {
    onSuccess: (data) => {
      dispatch(sessionReset(data.data.data));
    },
    onError: () => {},
  });

  useEffect(() => {
    const navigationType =
      window.performance.getEntriesByType('navigation')[0].type;

    // navigationType === 'reload';
    // navigationType === 'navigate';
    // navigationType === 'back_forward';
    // navigationType === 'prerender';

    if (navigationType === 'reload' && getToken()?.length > 0) {
      resetMutation.mutate();
    }
  }, []);

  const element = useRoutes([
    {
      path: HOME,
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: WORKSPACE.slice(1),
          element: <Workspace />,
        },
      ],
    },
    {
      path: SIGNUP,
      element:
        isUserAuthenticated() === true ? (
          <Navigate to={HOME} />
        ) : (
          <SignUp />
        ),
    },
    {
      path: LOGIN,
      element:
        isUserAuthenticated() === true ? (
          <Navigate to={HOME} />
        ) : (
          <Login />
        ),
    },
    { path: '*', element: <Navigate to={HOME} /> }, // if no route matches, redirect to Home page
  ]);

  return element;
}

export default App;
