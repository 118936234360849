import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function ChatItem(props) {
  const { isMyMsg, message } = props;
  return isMyMsg ? (
    <ChatItemWrapper isMe>
      <RightChatItem>
        <Typography variant="body2" component="p" color="primary">
          {message}
        </Typography>
        <Typography variant="caption" component="p" color="primary">
          10:34
        </Typography>
      </RightChatItem>
    </ChatItemWrapper>
  ) : (
    <ChatItemWrapper>
      <LeftChatItem>
        <Typography variant="body2" component="p" color="black">
          {message}
        </Typography>
        <Typography variant="caption" component="p" color="black">
          10:34
        </Typography>
      </LeftChatItem>
    </ChatItemWrapper>
  );
}

ChatItem.propTypes = {
  isMyMsg: PropTypes.bool,
  message: PropTypes.string,
};

ChatItem.defaultProps = {
  isMyMsg: false,
  message: '',
};

const ChatItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMe',
})(({ isMe }) => ({
  display: 'flex',
  justifyContent: isMe ? 'end' : 'start',
}));

const ChatElement = styled(Box)(({ theme }) => ({
  'boxShadow': '0px 4px 4px rgba(255, 255, 255, 0.25)',
  'marginBottom': theme.spacing(1),
  'padding': theme.spacing(1),
  'maxWidth': '70%',
  'width': 'max-content',
  '&>p:last-child': {
    marginTop: theme.spacing(0),
    textAlign: 'end',
  },
}));

const RightChatItem = styled(ChatElement)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: '10px 0px 10px 10px',
  float: 'right',
}));

const LeftChatItem = styled(ChatElement)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '0px 10px 10px 10px',
  float: 'left',
}));
