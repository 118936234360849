const typography =
{
  'fontFamily': 'Montserrat',
  'htmlFontSize': 10,
  'h1': {
    'fontSize': '3.2rem !important',
    'fontWeight': 'bold',
    'fontStretch': 'normal',
    'fontStyle': 'normal',
    'lineHeight': 1,
    'letterSpacing': 'normal',
  },
  'caption': {
    'fontSize': '1.0rem !important',
    'fontWeight': 'normal',
    'fontStretch': 'normal',
    'fontStyle': 'normal',
    'lineHeight': 1,
    'letterSpacing': 'normal',
  }
}

export default typography;