/* eslint-disable */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LOGIN } from '../../../constants/urlConstant';
import { isUserAuthenticated } from '../../../utilities/utils';

const PrivateRoute = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserAuthenticated() === false) {
      navigate(LOGIN, { replace: true });
    }
  }, []);

  // authorized so return child components
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
