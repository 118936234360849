// unauthenticated Routes
export const SIGNUP = '/signup';
export const LOGIN = '/login';

// Authenticated Routes
export const HOME = '/';
export const WORKSPACE = '/workspace';

// socket.io
export const SOCKETURL = 'https://videorooms.herokuapp.com/';
