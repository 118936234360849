import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { loginData } from '../../ReduxSlices/AppSlice';
import {
  StyledGrid,
  StyledCheckbox,
} from '../Common/SigningLayout/styles';
import SigningLayout from '../Common/SigningLayout/SigningLayout';
import { login } from '../../APIs/Services';
import ErrorAlert from '../../utilities/ErrorAlert';
import SuccessAlert from '../../utilities/SuccessAlert';
import { setToken } from '../../utilities/utils';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // component states
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  // redux state
  const registeredSuccessfully = useSelector(
    (state) => state.appState.registeredSuccessfully,
  );

  let formik = null;

  useEffect(() => {
    if (registeredSuccessfully) {
      setOpenAlert(true);
    }
  }, [registeredSuccessfully]);

  const loginMutation = useMutation(login, {
    onSuccess: (data) => {
      setToken(data.headers.token);
      if (data.data.message === 'success') {
        dispatch(loginData(data.data.data));
        formik.resetForm(); // reset form after successful submission
        navigate('/');
      } else {
        setOpenErrorAlert(true);
      }
    },
    onError: () => {
      setOpenErrorAlert(true);
    },
  });

  formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
      password: Yup.string()
        .min(6, 'Must be 6 charcters or more.')
        .required('Please enter your password'),
    }),
    onSubmit: (values) => {
      loginMutation.mutate(values);
    },
  });

  const alertCloseHandler = () => {
    if (openAlert) setOpenAlert(false);
    if (openErrorAlert) setOpenErrorAlert(false);
  };

  return (
    <SigningLayout isLogin>
      <SuccessAlert
        vertical="top"
        horizontal="right"
        open={openAlert}
        message="Registration Successful!"
        onCloseHandler={alertCloseHandler}
      />
      <ErrorAlert
        vertical="top"
        horizontal="right"
        open={openErrorAlert}
        message="Something went wrong!"
        onCloseHandler={alertCloseHandler}
      />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <StyledGrid xs={12}>
            <TextField
              id="email"
              name="email"
              color="primary"
              focused
              fullWidth
              label="Email"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              helperText={
                formik.touched.email && formik.errors.email
              }
            />
          </StyledGrid>
          <StyledGrid xs={12}>
            <TextField
              id="password"
              name="password"
              color="primary"
              focused
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={
                formik.touched.password && formik.errors.password
              }
              helperText={
                formik.touched.password && formik.errors.password
              }
            />
          </StyledGrid>
          <StyledGrid xs={12}>
            <Box
              component="div"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <StyledCheckbox
                label={{ inputProps: { 'aria-label': 'Checkbox' } }}
              />
              <Typography
                variant="subtitle1"
                component="p"
                color="primary"
              >
                Remember me
              </Typography>
            </Box>
          </StyledGrid>
          <Grid xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              disabled={loginMutation.isLoading}
            >
              {loginMutation.isLoading ? 'Loading...' : 'Sign In'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SigningLayout>
  );
}
