import axios from 'axios';

const API_URL = 'https://videorooms.herokuapp.com/';

const AuthAxios = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const register = (values) => {
  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
  };
  return axios.post(`${API_URL}auth/register`, payload);
};

export const login = async (values) => {
  const payload = {
    email: values.email,
    password: values.password,
  };
  return axios.post(`${API_URL}auth/login`, payload);
};

export const logout = async (values) => {
  const payload = {
    email: values.email,
  };
  return AuthAxios.post(`${API_URL}auth/logout`, payload);
};

export const resetSession = async () =>
  AuthAxios.get(`${API_URL}session`);
