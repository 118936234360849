/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import ChatItem from './ChatItem';
import { capitalizeFirstLetter } from '../../utilities/utils';
import { updateSentUsersChat } from '../../ReduxSlices/AppSlice';

export default function Chat(props) {
  // Props
  const { chatCloseHanlder } = props;

  // Local variables
  const dispatch = useDispatch();

  // Redux States
  const users = useSelector(
    (state) => state.appState.organization.users,
  );
  const socketInstance = useSelector(
    (state) => state.appState.socketInstance,
  );
  const socketUserId = useSelector(
    (state) => state.appState.user.socketUserId,
  );
  const usersChat = useSelector((state) => state.appState.usersChat);
  const userId = useSelector((state) => state.appState.user.id);

  // Local States
  const [isCharDetail, setIsCharDetail] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [chatText, setChatText] = useState('');
  const [selectedUserChatBox, setSelectedUserChatBox] =
    useState(null);
  const [peerChatMessages, setPeerChatMessages] = useState([]);

  // Component Logic
  useEffect(() => {
    if (selectedUserChatBox) {
      const filteredUserChat = usersChat.filter(
        (chat) => chat.userID === selectedUserChatBox.socketUserId,
      );
      setPeerChatMessages(filteredUserChat[0]?.messages);
      console.log(usersChat);
      console.log(filteredUserChat);
      console.log(selectedUserChatBox);
    }
  }, [usersChat, selectedUserChatBox]);

  useEffect(() => {
    if (users) {
      const filteredUsers = users.filter(
        (user) => user.userId !== userId,
      );
      setUsersList(filteredUsers);
    }
  }, [users, userId]);

  const searchUsersHandler = (value) => {
    setSearchTerm(value);
    const searchedUsers = users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      if (fullName.includes(value)) return user;
    });
    setUsersList(searchedUsers);
  };

  const chatTextChangeHandler = (value) => {
    setChatText(value);
  };

  const sendPrivateMessage = (to, content) => {
    if (content && socketInstance.connected) {
      // addChatMessageToDom();
      // tell server to execute 'new message' and send along one parameter
      socketInstance.emit('new private message', {
        to,
        from: socketUserId,
        content,
      });
      dispatch(
        updateSentUsersChat({ to, from: socketUserId, content }),
      );
      setChatText('');
    }
  };

  const userBoxClickHandler = (userData) => {
    setIsCharDetail(true);
    setSelectedUserChatBox(userData);
  };

  return (
    <CustomDrawer>
      <ChatHeader>
        {isCharDetail ? (
          <Box>
            <IconButton
              color="primary"
              aria-label="notification"
              edge="start"
              onClick={() => setIsCharDetail(false)}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" component="p" color="primary">
              Room Chat
            </Typography>
          </Box>
        ) : (
          <>
            <Typography variant="h6" component="p" color="primary">
              Chat
            </Typography>
            <IconButton
              color="primary"
              aria-label="notification"
              edge="start"
              onClick={chatCloseHanlder}
            >
              <CancelIcon />
            </IconButton>
          </>
        )}
      </ChatHeader>
      <ChatMain>
        {isCharDetail ? (
          <>
            <ChatDetailWrapper>
              {peerChatMessages?.map((message, index) => (
                <ChatItem
                  key={index}
                  isMyMsg={
                    message.to === selectedUserChatBox.socketUserId
                  }
                  message={message.content}
                />
              ))}
            </ChatDetailWrapper>
            <ChatTextWrapper>
              <TextField
                id="standard-multiline-static"
                label=""
                focused
                fullWidth
                multiline
                rows={4}
                value={chatText}
                onChange={(e) =>
                  chatTextChangeHandler(e.target.value)
                }
                sx={{ textArea: { color: 'white' } }}
              />
              <Button
                onClick={() =>
                  sendPrivateMessage(
                    selectedUserChatBox.socketUserId,
                    chatText,
                  )
                }
              >
                Send
              </Button>
            </ChatTextWrapper>
          </>
        ) : (
          <>
            <ChatSearchWrapper>
              <TextField
                label=""
                id="outlined-start-adornment"
                color="primary"
                focused
                size="small"
                fullWidth
                value={searchTerm}
                onChange={(e) => searchUsersHandler(e.target.value)}
                sx={{ input: { color: 'white' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </ChatSearchWrapper>
            <ChatsList>
              {searchTerm.length > 0 && usersList.length === 0 ? (
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '2rem',
                    fontWeight: 500,
                  }}
                >
                  No user(s) found against your search!
                </Typography>
              ) : usersList.length === 0 ? (
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '2rem',
                    fontWeight: 500,
                  }}
                >
                  No user(s) available!
                </Typography>
              ) : (
                usersList.map((user) => (
                  <ChatItemWrapper key={user.userId}>
                    <Box
                      key={user.userId}
                      onClick={() => userBoxClickHandler(user)}
                    >
                      <ChatAvatar
                        alt={capitalizeFirstLetter(user.firstName)}
                        src="/static/images/avatar/1.jpg"
                      />
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          color="primary"
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="p"
                          color="primary"
                        >
                          Amet minim mollit non deserunt ...
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="primary"
                      >
                        2h ago
                      </Typography>
                    </Box>
                  </ChatItemWrapper>
                ))
              )}
            </ChatsList>
          </>
        )}
      </ChatMain>
    </CustomDrawer>
  );
}

Chat.propTypes = {
  chatCloseHanlder: PropTypes.func.isRequired,
};

const CustomDrawer = styled(Box)(({ theme }) => ({
  width: '24vw',
  backgroundColor: theme.palette.navigation.main,
  marginRight: theme.spacing(0),
  height: '84vh',
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  'borderRadius': '1rem',
  'backgroundColor': theme.palette.secondary.main,
  'display': 'flex',
  'justifyContent': 'space-between',
  'height': theme.spacing(9),
  'alignItems': 'center',
  'padding': `0 ${theme.spacing(0)}`,
  'marginBottom': theme.spacing(0),
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0),
  },
}));

const ChatMain = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(0)}`,
  height: '90%',
}));

const ChatSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '1rem',
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor: theme.palette.layout.black,
  height: theme.spacing(9),
  marginBottom: theme.spacing(0),
}));

const ChatsList = styled(Box)(() => ({
  height: '85%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

const ChatItemWrapper = styled(ChatSearchWrapper)(({ theme }) => ({
  'cursor': 'pointer',
  'justifyContent': 'space-between',
  '&>div': {
    'display': 'flex',
    'alignItems': 'center',
    '&>div': {
      marginLeft: theme.spacing(0),
    },
  },
}));

const ChatAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const ChatTextWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(9),
  width: '22vw',
  background: theme.palette.layout.black,
}));

const ChatDetailWrapper = styled(Box)(() => ({
  height: '82%',
  overflow: 'auto',
}));

/* eslint-enable */