/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function MediaPlayer({ videoTrack, audioTrack, isLocal, isVideoEnabled }) {
  console.log(`Inside ${isVideoEnabled}`);
  const container = useRef(null);
  useEffect(() => {
    if (!container.current) return;
    videoTrack?.play(container.current);

    // eslint-disable-next-line consistent-return
    return () => {
      videoTrack?.stop();
    };
  }, [container, videoTrack]);

  useEffect(() => {
    // console.log('isVideoEnabled: ' + props.isVideoEnabled);
    if (isLocal) {
      if (!isVideoEnabled) {
        if (container.current.childNodes.length > 0) {
          if (container.current.childNodes[0].childNodes.length > 0) {
            container.current.childNodes[0].childNodes[0].style.display = "none";
          }
        }
      } else if (container.current.childNodes.length > 0) {
        if (container.current.childNodes[0].childNodes.length > 0) {
          container.current.childNodes[0].childNodes[0].style.display = "block";
        }
      }
    }
  }, [isVideoEnabled, isLocal]);

  useEffect(() => {
    audioTrack?.play();
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack]);

  return <div ref={container} className="video-player" />;
}

export default MediaPlayer;

MediaPlayer.propTypes = {
  videoTrack: PropTypes.object,
  audioTrack: PropTypes.object,
  isLocal: PropTypes.bool,
  isVideoEnabled: PropTypes.bool,
};

MediaPlayer.defaultProps = {
  videoTrack: null,
  audioTrack: null,
  isLocal: false,
  isVideoEnabled: false,
}