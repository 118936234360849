import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import Slide from '@mui/material/Slide';

/*eslint-disable */
export default function SuccessAlert({
  vertical,
  horizontal,
  open,
  message,
  onCloseHandler,
}) {
  const Transition = Slide;

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={onCloseHandler}
        key={vertical + horizontal}
        autoHideDuration={3000}
        TransitionComponent={Transition}
      >
        <Alert
          color="info"
          severity="success"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
