import React from 'react'
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { LOGIN, SIGNUP } from "../../../constants/urlConstant";
import background from '../../../assets/images/blur-gradient.png';
import logo from '../../../assets/images/logo-v2.svg';


export default function SigningLayout(props) {
  const { children, isLogin } = props;
  const bottomDesc = {
    'login': {
      heading: 'Sign in',
      initialText: `No registered? `,
      href: SIGNUP,
      linkText: `Create your video room`,
    },
    'signup': {
      heading: 'Create your account',
      initialText: `Already registered? `,
      href: LOGIN,
      linkText: `Login`,
    }
  };
  const object = bottomDesc[isLogin ? 'login' : 'signup'];
  return (
    <Wrapper component="div">
      <Logo src={logo} alt='videoroom-logo' />
      <FormWrapper>
        <Heading variant="h1" color='primary'>
          {object.heading}
        </Heading>
        {children}
        <Grid
          container
          spacing={2}
        >
          <Grid xs={12}
            textAlign="center"
          >
            <Typography variant="subtitle1" component="p" color='primary'>
              {object.initialText}
              <Link to={object.href}>{object.linkText}</Link>
            </Typography>
          </Grid>
        </Grid>
      </FormWrapper>
    </Wrapper>
  )
}

SigningLayout.propTypes = {
  isLogin: PropTypes.bool,
  children: PropTypes.node.isRequired
}

SigningLayout.defaultProps = {
  isLogin: false,
}

const Wrapper = styled(Box)(
  ({ theme }) => ({
    height: '100vh',
    width: '100vw',
    padding: theme.spacing(1),
    background: `url('${background}')`,
    backgroundColor: 'black',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
);

const FormWrapper = styled(Box)(
  () => ({
    minHeight: '30vh',
    width: '45vw',
    maxWidth: '778px',
    background: 'rgba(255, 255, 255, 0.1)',
    padding: '72px 80px',
    borderadius: '10px'
  }),
);

const Logo = styled('img')(
  () => ({
    position: 'absolute',
    top: '32px',
    left: '32px',
  }),
);

const Heading = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(5)
  }),
);
