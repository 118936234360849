/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SettingsIcon from '@mui/icons-material/Settings';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ChatIcon from '@mui/icons-material/Chat';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import StopScreenShareRoundedIcon from '@mui/icons-material/StopScreenShareRounded';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import AgoraRTC from 'agora-rtc-sdk-ng';
import io from 'socket.io-client';
import logo from '../../../assets/images/logo-v2.svg';
import Chat from '../../Chat/Chat';
import {
  WORKSPACE,
  HOME,
  SOCKETURL,
} from '../../../constants/urlConstant';
import {
  setAudioTrack,
  setVideoTrack,
  editScreenUser,
  setSocketInstance,
  setUsersChat,
  updateReceiveUsersChat,
} from '../../../ReduxSlices/AppSlice';
import {
  appId,
  drawerWidth,
  getToken,
  screenClient,
} from '../../../utilities/utils';

export default function MainLayout() {
  // Redux States
  const videoTrackEnabled = useSelector(
    (state) => state.appState.rtc.users.main.videoTrackEnabled,
  );
  const videoTrackState = useSelector(
    (state) => state.appState.rtc.users.main.videoTrack,
  );
  const audioTrackEnabled = useSelector(
    (state) => state.appState.rtc.users.main.audioTrackEnabled,
  );
  const audioTrackState = useSelector(
    (state) => state.appState.rtc.users.main.audioTrack,
  );
  const workChannelId = useSelector(
    (state) => state.appState.rtc.workChannelId,
  );
  const workChannelScreenToken = useSelector(
    (state) => state.appState.work?.workChannels?.[0]?.screenToken,
  );
  const userScreenId = useSelector(
    (state) => state.appState.user.screenId,
  );
  const screenTrackState = useSelector(
    (state) =>
      state.appState.rtc.screenUsers?.[userScreenId]?.videoTrack,
  );
  const socketSessionId = useSelector(
    (state) => state.appState.user.socketSessionId,
  );
  const socketUserId = useSelector(
    (state) => state.appState.user.socketUserId,
  );
  const organizationId = useSelector(
    (state) => state.appState.organization.id,
  );

  // Local Variables
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = io(SOCKETURL, { autoConnect: false });
  const token = getToken();

  // Local States
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [dialogAction, setDialogAction] = useState({
    open: false,
    type: '',
  });
  const [shareScreen, setShareScreen] = useState(false);

  // Component Logic
  useEffect(() => {
    // Fired upon a connection error.
    socket.io.on('error', (error) => {
      console.error(`IO Event: "error"`, error);
    });

    //
    socket.io.on('reconnect', () => {
      console.log(`IO Event: "reconnect"`, 'Socket reconnected');
    });

    // Fired upon connection to the Namespace (including a successful reconnection).
    socket.on('connect', () => {
      dispatch(setSocketInstance(socket));
      console.log('in Connect Callback', socket);
      console.log(
        `Socket Event: "connect"`,
        `Initial socket connected, waiting for 'session' auth event`,
      );
    });

    socket.on('disconnect', (reason) => {
      dispatch(setSocketInstance(socket));
      console.log('in Disconnect Callback', socket);
      console.log(
        `Socket Event: "disconnect"`,
        `Socket disconnected`,
      );
      if (reason !== 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        console.log(
          `It's a server sent disconnection, check connection credentials`,
        );
      }
      console.log(
        'You have natural disconnection, check your network',
      );
    });

    // 1. Fired when the server acknowledges the connection as complete
    socket.on('session', (sessiondata) => {
      const {
        sessionID,
        userID,
        organizationID,
        firstName,
        lastName,
      } = sessiondata;
      // attach the session data to the next reconnection attempts
      socket.auth = {
        sessionID,
        userID,
        organizationID,
        firstName,
        lastName,
      };
      console.log(
        `Socket Event: "session"`,
        'Connection establishment complete',
      );
    });

    // 2. Gives all my chats with other users in Organization
    socket.on('peers and chats', (users) => {
      if (users) {
        dispatch(setUsersChat(users));
        console.log(
          `Socket Event: "peers and chats"`,
          'Get the state of your previous sessions, users etc.',
          users,
        );
      } else {
        console.error(
          `Socket Event: "peers and chats"`,
          'Invalid users body',
        );
      }
    });

    // 3. Fired to notify existing users about new connected user in Organization
    socket.on('user connected', (user) => {
      if (user) {
        console.log(
          `Socket Event: "user connected"`,
          'Get the state of newly connected user in organization',
          user,
        );
      } else {
        console.error(
          `Socket Event: "user connected"`,
          'Invalid users body',
        );
      }
    });

    // 4. Fired to notify existing users about a user leaving the app in Organization
    socket.on('user disconnected', (userSocketId) => {
      if (userSocketId) {
        console.log(
          `Socket Event: "user disconnected"`,
          'A user just left or disconnected from our app userSocketId: ',
          userSocketId,
        );
      } else {
        console.error(
          `Socket Event: "user disconnected"`,
          'Invalid user details',
        );
      }
    });

    // Fired upon connection to the Namespace (including a successful reconnection).
    socket.on('new private message', (payload) => {
      dispatch(updateReceiveUsersChat(payload));
      console.log(
        `Socket Event: "new private message"`,
        `Private Message Received: `,
        payload,
      );
    });

    // socket.on('connect_error', (err) => {
    //   if (err.message === 'invalid username') {
    //     this.usernameAlreadySelected = false;
    //   }
    // });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  }, []);

  useEffect(() => {
    if (token && socketUserId && socketSessionId && organizationId) {
      socket.auth = {
        token,
        socketUserId,
        socketSessionId,
        organizationId,
      };
      console.log(
        token,
        socketUserId,
        socketSessionId,
        organizationId,
      );
      socket.connect();
    }
  }, [token, socketUserId, socketSessionId, organizationId]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChatDrawer = (flag) => {
    setOpenChat(flag);
  };

  const handleDialog = (open, type) => {
    setDialogAction({ open, type });
  };

  const changeSettings = async (track, flag) => {
    if (track) {
      await track.setEnabled(flag);
      // if (flag) {
      //   await client.publish([track]);
      // }
    }
  };

  // const switchSettings = async (track, deviceId) => {
  //   await track.setDevice(deviceId);
  // };

  const microphoneHandler = async () => {
    const flag = !audioTrackEnabled;
    await changeSettings(audioTrackState, flag);
    dispatch(setAudioTrack(flag));
  };
  const cameraHandler = async () => {
    const flag = !videoTrackEnabled;
    await changeSettings(videoTrackState, flag);
    dispatch(setVideoTrack(flag));
  };

  const shareScreeningHandler = async () => {
    if (shareScreen) {
      await screenClient.unpublish(screenTrackState);
      await screenClient.leave();
    } else {
      const screenTrack = await AgoraRTC.createScreenVideoTrack();
      dispatch(
        editScreenUser({ track: screenTrack, id: userScreenId }),
      );
      await screenClient.join(
        appId,
        workChannelId,
        workChannelScreenToken,
        userScreenId,
      );
      await screenClient.publish(screenTrack);
    }
    setShareScreen(!shareScreen);
  };

  // const switchMicrophone = (e) => {
  //   const deviceId = e.target.value;
  //   switchSettings(audioTrackState, deviceId);
  // };

  // const switchCamera = (e) => {
  //   const deviceId = e.target.value;
  //   switchSettings(videoTrackState, deviceId);
  // };

  return (
    <MainLayoutWrapper>
      <StyledAppBar position="relative" color="navigation">
        <Toolbar variant="dense" sx={{ justifyContent: 'end' }}>
          <Tooltip title="Notification" arrow>
            <IconButton
              color="inherit"
              aria-label="notification"
              edge="start"
              onClick={handleMenu}
            >
              <CircleNotificationsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
          </Menu>
          <Tooltip title="Settings" arrow>
            <IconButton
              color="inherit"
              aria-label="settings"
              edge="start"
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer variant="permanent" anchor="left">
        <SideBarBox>
          <img src={logo} alt="videoroom-logo" />
        </SideBarBox>
        <SideBarBox>
          <List>
            {[
              {
                id: 'space',
                icon: MeetingRoomIcon,
                text: 'Move space',
                onClick: () => navigate(HOME),
              },
              {
                id: 'chat',
                icon: ChatIcon,
                text: 'Chat',
                onClick: () => handleChatDrawer(!openChat),
              },
              {
                id: 'invite',
                icon: ContactMailIcon,
                text: 'Invite Guest',
                onClick: () => handleDialog(true, 'guest'),
              },
            ].map((item) => {
              const IconComponent = item.icon;
              return (
                <StyledListItem key={`${item.id}`} disablePadding>
                  <ListItemButton onClick={item.onClick}>
                    <ListItemIcon
                      sx={{
                        justifyContent: 'center',
                      }}
                    >
                      <IconComponent />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        color: 'primary',
                        component: 'p',
                        variant: 'caption',
                      }}
                    />
                  </ListItemButton>
                </StyledListItem>
              );
            })}
          </List>
        </SideBarBox>
        <BottomSideBarPortion>
          <AddUserBtn
            variant="text"
            onClick={() => handleDialog(true, 'users')}
            startIcon={<PersonAddAltSharpIcon />}
          >
            Add users
          </AddUserBtn>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
          </StyledBadge>
        </BottomSideBarPortion>
      </StyledDrawer>
      <MainWrapper component="main">
        <StyledGrid container spacing={2}>
          {openChat && (
            <Grid xs="auto">
              <Chat
                chatCloseHanlder={() => handleChatDrawer(false)}
              />
            </Grid>
          )}
          <Grid xs>
            <Outlet context={[openChat]} />
          </Grid>
        </StyledGrid>
      </MainWrapper>
      <StyledAppBar
        position="relative"
        sx={{ top: 'auto', bottom: 0 }}
        color="navigation"
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {pathname === WORKSPACE && (
            <>
              <Box />
              <Box>
                <Tooltip title="Toggle Video" arrow>
                  <RoomIconButton
                    color="inherit"
                    aria-label="video"
                    edge="start"
                    onClick={cameraHandler}
                  >
                    {videoTrackEnabled ? (
                      <VideocamIcon />
                    ) : (
                      <VideocamOffIcon />
                    )}
                  </RoomIconButton>
                </Tooltip>
                <Tooltip title="Toggle Audio" arrow>
                  <RoomIconButton
                    color="inherit"
                    aria-label="audio"
                    edge="start"
                    onClick={microphoneHandler}
                  >
                    {audioTrackEnabled ? (
                      <MicIcon />
                    ) : (
                      <MicOffIcon />
                    )}
                  </RoomIconButton>
                </Tooltip>
                <Tooltip title="Toggle Meeting View" arrow>
                  <RoomIconButton
                    color="inherit"
                    aria-label="meeting view"
                    edge="start"
                  >
                    <GridViewRoundedIcon />
                  </RoomIconButton>
                </Tooltip>
                <Tooltip title="Toggle Screen Share" arrow>
                  <RoomIconButton
                    color="inherit"
                    aria-label="screen share"
                    edge="start"
                    onClick={shareScreeningHandler}
                  >
                    {shareScreen ? (
                      <ScreenShareRoundedIcon />
                    ) : (
                      <StopScreenShareRoundedIcon />
                    )}
                  </RoomIconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Toggle Room Audio" arrow>
                  <RoomIconButton
                    color="inherit"
                    aria-label="room audio"
                    edge="start"
                  >
                    <VolumeUpIcon />
                  </RoomIconButton>
                </Tooltip>
                <Badge badgeContent={4} color="primary">
                  <Tooltip title="Toggle Participants" arrow>
                    <RoomIconButton
                      noMarginRight
                      color="inherit"
                      aria-label="room lock"
                      edge="start"
                    >
                      <PeopleIcon />
                    </RoomIconButton>
                  </Tooltip>
                </Badge>
              </Box>
            </>
          )}
        </Toolbar>
      </StyledAppBar>
      <StyledDialog
        open={dialogAction.open}
        fullWidth
        maxWidth="sm"
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <DialogTypography variant="h5" align="center">
            {dialogAction.type === 'guest'
              ? 'Invite Guest'
              : 'Add User'}
          </DialogTypography>
          <DialogCloseIcon
            aria-label="close"
            onClick={() => handleDialog(false, '')}
          >
            <CloseIcon />
          </DialogCloseIcon>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" color="black">
            Copy and share this link...for them to join your space
          </Typography>
          <LinkWrapper>
            <Typography color="black">
              https://app.videoroom.io/public/team-member...
            </Typography>
            <IconButton aria-label="copy content">
              <ContentCopyIcon />
            </IconButton>
          </LinkWrapper>
        </DialogContent>
      </StyledDialog>
    </MainLayoutWrapper>
  );
}

const MainLayoutWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}));

const StyledAppBar = styled(AppBar)(() => ({
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `${drawerWidth}px`,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  'width': drawerWidth,
  'flexShrink': 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.navigation.main,
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)} 0`,
  },
}));

const SideBarBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const BottomSideBarPortion = styled(SideBarBox)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  'marginBottom': theme.spacing(4),
  '& .MuiListItemButton-root': {
    flexDirection: 'column',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    'backgroundColor': '#44b700',
    'color': '#44b700',
    'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  backgroundColor: theme.palette.layout.black,
  marginLeft: `${drawerWidth}px`,
}));

const StyledGrid = styled(Grid)(() => ({
  width: '100%',
}));

const AddUserBtn = styled(Button)(({ theme }) => ({
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'fontSize': `1.0rem !important`,
  'marginBottom': theme.spacing(2),
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

const RoomIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'noMarginRight',
})(({ theme, noMarginRight }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  marginRight: theme.spacing(3),
  ...(!noMarginRight
    ? { marginRight: theme.spacing(3) }
    : { marginRight: 0 }),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

const DialogTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.layout.black,
  fontWeight: 'bold',
}));

const DialogCloseIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(5),
  top: theme.spacing(5),
  color: theme.palette.layout.black,
}));

const LinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${theme.palette.layout.black}`,
  borderRadius: theme.spacing(0),
  padding: theme.spacing(1),
  marginTop: theme.spacing(5),
  backgroundColor: theme.palette.layout.cultured,
}));
