import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable */

const initialState = {
  userId: '',
  registeredSuccessfully: false,
  meet: {},
  organization: {},
  social: {},
  user: {},
  work: {},
  rtc: {
    microphonesList: [],
    camerasList: [],
    workChannelId: '',
    workChannelToken: '',
    users: {
      main: {
        audioTrack: null,
        videoTrack: null,
        audioTrackEnabled: true,
        videoTrackEnabled: true,
        uid: '',
      },
    },
    screenUsers: {},
    currentScreenShareUserId: [], // add using shift() and print the first element
  },
  socketInstance: null,
  usersChat: [],
};

export const AppSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setRegistrationState: (state, action) => {
      state.registeredSuccessfully = action.payload;
    },
    loginData: (state, action) => {
      state.meet = action.payload.meet;
      state.organization = action.payload.organization;
      state.social = action.payload.social;
      state.user = action.payload.user;
      state.work = action.payload.work;
      state.rtc.workChannelId =
        action.payload.work.workChannels[0].workChannelId;
      state.rtc.workChannelToken =
        action.payload.work.workChannels[0].token;
      state.userId = action.payload.user.id;
    },
    setRtc: (state, action) => {
      state.rtc.microphonesList = action.payload.microphones;
      state.rtc.camerasList = action.payload.cameras;
      state.rtc.users.main = {
        ...state.rtc.users.main,
        audioTrack: action.payload.microphoneTrack,
        videoTrack: action.payload.cameraTrack,
      };
    },
    addRemoteUser: (state, action) => {
      state.rtc.users = {
        ...state.rtc.users,
        [action.payload.uid]: action.payload,
      };
    },
    editRemoteUser: (state, action) => {
      const updateUser = action.payload;
      state.rtc.users = {
        ...state.rtc.users,
        [updateUser.uid]: updateUser,
      };
    },
    removeRemoteUser: (state, action) => {
      const updatedUsers = { ...state.rtc.users };
      delete updatedUsers[action.payload];
      state.rtc.users = {
        ...(state.rtc.users = updatedUsers),
      };
    },
    addRemoteScreenUser: (state, action) => {
      state.rtc.screenUsers = {
        ...state.rtc.screenUsers,
        [action.payload.uid]: action.payload,
      };
    },
    editScreenUser: (state, action) => {
      state.rtc.screenUsers = {
        ...state.rtc.screenUsers,
        [action.payload.id]: {
          videoTrack: action.payload.track,
        },
      };
    },
    removeScreenUser: (state, action) => {
      const updatedScreenUsers = { ...state.rtc.screenUsers };
      delete updatedScreenUsers[action.payload];
      state.rtc.screenUsers = {
        ...(state.rtc.screenUsers = updatedScreenUsers),
      };
      state.rtc.currentScreenShareUserId = state.rtc.currentScreenShareUserId.filter((item) => item !== action.payload);
    },
    setScreenShareId: (state, action) => {
      const screenShareList = [...state.rtc.currentScreenShareUserId];
      state.rtc.currentScreenShareUserId = screenShareList.unshift(action.payload);
    },
    setAudioTrack: (state, action) => {
      state.rtc.users.main = {
        ...state.rtc.users.main,
        audioTrackEnabled: action.payload,
      };
    },
    setVideoTrack: (state, action) => {
      state.rtc.users.main = {
        ...state.rtc.users.main,
        videoTrackEnabled: action.payload,
      };
    },
    sessionReset: (state, action) => {
      state.meet = action.payload.meet;
      state.organization = action.payload.organization;
      state.social = action.payload.social;
      state.user = action.payload.user;
      state.work = action.payload.work;
    },
    setSocketInstance: (state, action) => {
      state.socketInstance = action.payload;
    },
    setUsersChat: (state, action) => {
      state.usersChat = action.payload;
    },
    updateSentUsersChat: (state, action) => {
      const newState = state.usersChat.map((chatObject) => {
        if (chatObject.userID === action.payload.to) {
          chatObject.messages = [
            ...chatObject.messages,
            action.payload,
          ];
        }
        return chatObject;
      });
      state.usersChat = newState;
    },
    updateReceiveUsersChat: (state, action) => {
      const newState = state.usersChat.map((chatObject) => {
        if (chatObject.userID === action.payload.from) {
          chatObject.messages = [
            ...chatObject.messages,
            action.payload,
          ];
        }
        return chatObject;
      });
      state.usersChat = newState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRegistrationState,
  loginData,
  setRtc,
  addRemoteUser,
  editRemoteUser,
  removeRemoteUser,
  addRemoteScreenUser,
  setScreenShareId,
  editScreenUser,
  removeScreenUser,
  setAudioTrack,
  setVideoTrack,
  sessionReset,
  setSocketInstance,
  setUsersChat,
  updateSentUsersChat,
  updateReceiveUsersChat,
} = AppSlice.actions;

export default AppSlice.reducer;

/* eslint-enable */