import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SigningLayout from '../Common/SigningLayout/SigningLayout';
import {
  StyledGrid,
  StyledCheckbox,
} from '../Common/SigningLayout/styles';
import { register } from '../../APIs/Services';
import SuccessAlert from '../../utilities/SuccessAlert';
import ErrorAlert from '../../utilities/ErrorAlert';
import { setRegistrationState } from '../../ReduxSlices/AppSlice';

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // component states
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [conditionsChecked, setConditionsChecked] = useState(false);

  let formik = null;

  const registerMutation = useMutation(register, {
    onSuccess: (data) => {
      if (data.data.message === 'success') {
        setOpenAlert(true);
        dispatch(setRegistrationState(true));
        formik.resetForm(); // reset form after successful submission
        navigate('/login');
      } else {
        setOpenErrorAlert(true);
      }
    },
    onError: () => {
      setOpenErrorAlert(true);
    },
  });

  formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required('Please enter your first name'),
      lastName: Yup.string()
        .trim()
        .required('Please enter your last name'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
      password: Yup.string()
        .min(6, 'Must be 6 charcters or more.')
        .required('Please enter your password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),
    onSubmit: (values) => {
      registerMutation.mutate(values);
    },
  });

  const alertCloseHandler = () => {
    if (openAlert) setOpenAlert(false);
    if (openErrorAlert) setOpenErrorAlert(false);
  };

  const conditionCheckboxHandler = (event) => {
    setConditionsChecked(event.target.checked);
  };

  return (
    <SigningLayout>
      <SuccessAlert
        vertical="top"
        horizontal="right"
        open={openAlert}
        message="Registration Successful!"
        onCloseHandler={alertCloseHandler}
      />
      <ErrorAlert
        vertical="top"
        horizontal="right"
        open={openErrorAlert}
        message="Something went wrong!"
        onCloseHandler={alertCloseHandler}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <StyledGrid xs={12} md={6}>
            <TextField
              id="firstName"
              name="firstName"
              fullWidth
              focused
              label="First Name"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && formik.errors.firstName
              }
              helperText={
                formik.touched.firstName && formik.errors.firstName
              }
            />
          </StyledGrid>
          <StyledGrid xs={12} md={6}>
            <TextField
              id="lastName"
              name="lastName"
              fullWidth
              focused
              label="Last Name"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName
              }
              helperText={
                formik.touched.lastName && formik.errors.lastName
              }
            />
          </StyledGrid>
          <StyledGrid xs={12}>
            <TextField
              id="email"
              name="email"
              focused
              fullWidth
              label="Email"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              helperText={
                formik.touched.email && formik.errors.email
              }
            />
          </StyledGrid>
          <StyledGrid xs={12} md={6}>
            <TextField
              id="password"
              name="password"
              focused
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={
                formik.touched.password && formik.errors.password
              }
              helperText={
                formik.touched.password && formik.errors.password
              }
            />
          </StyledGrid>
          <StyledGrid xs={12} md={6}>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              focused
              fullWidth
              label="Confirm Password"
              variant="outlined"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword &&
                formik.errors.confirmPassword
              }
            />
          </StyledGrid>
          <StyledGrid xs={12}>
            <Box
              component="div"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <StyledCheckbox
                onChange={conditionCheckboxHandler}
                label={{
                  inputProps: { 'aria-label': 'Checkbox' },
                }}
              />
              <Typography
                variant="subtitle1"
                component="p"
                color="primary"
              >
                I agree to Videorooms Terms & Conditions and Privacy
                policy.
              </Typography>
            </Box>
          </StyledGrid>
          <Grid xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              disabled={
                !conditionsChecked || registerMutation.isLoading
              }
            >
              {registerMutation.isLoading ? 'Loading...' : 'Sign Up'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SigningLayout>
  );
}
