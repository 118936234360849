// Spacing Constants Theme index from 0 to 9
export const spacingxxs = '0.8rem';//  0
export const spacingxs = '1.2rem';//  1
export const spacings = '1.6rem'; //  2
export const spacingm = '2.0rem'; //  3
export const spacingl = '2.4rem'; //  4
export const spacingxl = '3.2rem';//  5
export const spacingxxl = '4.0rem'; //  6
export const spacingxxl2 = '4.8rem';//  7
export const spacingxxl3 = '5.6rem';//  8
export const spacingxxl4 = '6.4rem';//  9
export const spacingxxl5 = '7.2rem';//  10