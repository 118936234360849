import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate, useOutletContext } from 'react-router-dom';
import work from '../../assets/images/work.svg';
import socialize from '../../assets/images/socialize.svg';
import meet from '../../assets/images/meet.svg';
import { WORKSPACE } from '../../constants/urlConstant';

export default function Home() {
  const navigate = useNavigate();
  const [openChat] = useOutletContext();

  const clickHandler = () => {
    navigate(WORKSPACE);
  };

  const images = [
    {
      src: work,
      alt: 'work workspace',
      onClick: clickHandler,
    },
    {
      src: socialize,
      alt: 'socialize workspace',
      onClick: clickHandler,
    },
    {
      src: meet,
      alt: 'meet workspace',
      onClick: clickHandler,
    },
  ];

  return (
    <HomeWrapper>
      <ImagesWrapper openChat={openChat}>
        {images.map((image) => (
          <img
            key={image.alt}
            src={image.src}
            alt={image.alt}
            onClick={image.onClick}
            aria-hidden="true"
          />
        ))}
      </ImagesWrapper>
    </HomeWrapper>
  );
}

const HomeWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
}));

const ImagesWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'openChat',
})(({ openChat }) => ({
  'display': 'flex',
  'justifyContent': 'center',
  'height': `${openChat ? '65vh' : '70vh'}`,
  '& img': {
    height: 'inherit',
    cursor: 'pointer',
  },
}));
